export function timeCut(time) {
    const date = new Date(time)
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`; //小时
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`; //分钟
    // const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : `${date.getSeconds()}`; //秒钟
    let value = ''
    return value = `${year}-${month}-${day} ${hours}:${minutes}`

}