<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" style="padding-bottom: 5rem;
  box-sizing: border-box;">
    <!-- 个人中心 -->
    <div class="user_info">
      <notic :ScrollObj="ScrollObj" />
      <Title title="个人信息" />
      <div class="user flex">
        <div class="user_user_box flex">
          <div>
            <p class="name">用户名</p>
            <p class="info_0">{{ user_info.username }}</p>
          </div>
          <div>
            <p class="name">手机号</p>
            <p class="info_0">{{ user_info.mobile }}</p>
          </div>
          <div>
            <p class="name">会员等级</p>
            <p class="info_0">{{ user_info.group_name }}</p>
          </div>
          <div>
            <p class="name">账户余额</p>
            <p class="info_0">￥{{ user_info.money }}</p>
          </div>
          <div>
            <p class="name">钱包</p>
            <p class="info_0">￥{{ user_info.wallet }}</p>
          </div>
          <button class="topUp" @click="childTab({
    title: '在线充值',
    activeTitle: '在线充值'
  })">立即充值</button>
        </div>
        <div class="flex" v-if="user_info.custom_info">
          <img :src="user_info.custom_info.avatar" alt="" style="width: 10rem;">
          <div class="user_name_info flex" v-if="user_info.custom_info">
            <p>专属客户经理</p>
            <p>姓名: {{ user_info.custom_info.nickname }}</p>
            <p>QQ: {{ user_info.custom_info.qq }}</p>
            <p>手机号: {{ user_info.custom_info.mobile }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="user_info2">
      <Title title="常用功能" />
      <div class="user2 flex">
        <div @click="childTab(item)" v-for="(item, index) in FunList" :key="index">
          <img :src="item.imgUrl" alt="">
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
    <div class="user_info3">
      <div class="user_status flex">
        <Title title="近3天订单状态" />
        <ul class="status_box flex">
          <li @click="tabSwitch(item)" :class="order_status.id === item.id ? 'active' : ''"
            v-for="(item, index) in StatusList" :key="index">{{ item.title }}</li>
        </ul>
      </div>
      <el-table :fit="true" :data="tableData" style="width: 100%;margin-bottom: 20px;" height="230">
        <el-table-column fixed prop="sn" label="订单号" width="150">
        </el-table-column>
        <el-table-column fixed prop="title" label="标题" width="300">
        </el-table-column>
        <el-table-column fixed prop="date" label="媒介类别" width="150">
          <template slot-scope="scope">
            <p class="media_num">{{ scope.row.category_title }}</p>
          </template>
        </el-table-column>
        <el-table-column fixed prop="date" label="媒介名称" width="150">
          <template slot-scope="scope">
            <p class="media_num">{{ scope.row.medium_title }}</p>
          </template>
        </el-table-column>
        <el-table-column fixed prop="order_price" label="金额">
        </el-table-column>
        <el-table-column fixed prop="date" label="状态">
          <template slot-scope="scope">
            <span style="">{{
    scope.row.order_status == 0 ? '未处理' : scope.row.order_status == 1 ? '发布中' : scope.row.order_status == 2 ?
      '已发稿' : scope.row.order_status == 0 ? '申请退款' : scope.row.order_status == 4 ? '拒绝退款' : '撤稿' }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed prop="order_time" label="下单时间">
        </el-table-column>
      </el-table>
    </div>
    <pagination :page="page" @pageChange="pageChange" :count="count"  :total_page="total_page" />

  </div>
</template>
<script>
import Title from '@/components/UserInfoTitle.vue'
import notic from '@/components/notic.vue'
import { mapState, mapMutations } from 'vuex'
import { timeCut } from '@/util/timeCut'
export default {
  name: '',
  components: {
    Title,
    notic
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  created() {
    this.getUserInfo()
  },
  mounted() {
    this.fullscreenLoading = true
    this.listFn()
    this.getNotic()
    this.fullscreenLoading = false
  },
  data() {
    return {
      ScrollObj:[],
      FunList: [
        {
          imgUrl: require('@/assets/user/index/zaixianchongzhi.png'),
          title: '在线充值',
          activeTitle: '在线充值',
        },
        {
          imgUrl: require('@/assets/user/index/fapiaoshenqing.png'),
          title: '发票申请',
          activeTitle: '申请发票',
        },
        {
          imgUrl: require('@/assets/user/index/meitiziyuan.png'),
          title: '媒介资源',
          activeTitle: '选择媒介',
        },
        {
          imgUrl: require('@/assets/user/index/Chat.png'),
          title: '一言一文',
          activeTitle: '一言一文',
        },
        {
          imgUrl: require('@/assets/user/index/changyonggongneng.png'),
          title: '工单管理',
          activeTitle: '我的工单',
        },
        {
          imgUrl: require('@/assets/user/index/lishifabu.png'),
          title: '历史发布',
          activeTitle: '媒介订单',
        },
        {
          imgUrl: require('@/assets/user/index/tuigaojilu.png'),
          title: '退稿记录',
          activeTitle: '退稿记录',
        },
      ],
      StatusList: [{
        id: '',
        title: '全部',
      }, {
        id: 0,
        title: '未处理'
      }, {
        id: 1,
        title: '发布中'
      }, {
        id: 2,
        title: '已出稿'
      }, {
        id: 3,
        title: '申请退款'
      }, {
        id: 4,
        title: '拒绝退款'
      }, {
        id: 5,
        title: '撤稿'
      },],
      tableData: [],
      fullscreenLoading: false,
      page:1,
      total_page: 0, //分页
      count: 0, //总条数
      order_status: {
        id: '',
        title: '全部'
      },
      user_info: {},
    }
  },
  methods: {
    getNotic(){
      this.curlGet('/api/gonggao').then(res=>{

        if(res.data.code){
          this.ScrollObj = res.data.data
        }
      })
    },
    pageChange(pageVal) {
      console.log(pageVal);
      this.page = pageVal
      this.listFn()
    },
    // 获取用户信息
    getUserInfo() {
      this.curlGet('/api/user/info', {}).then(res => {
        if (res.data.code) {
          this.user_info = res.data.data
          this.$user_info = res.data.data
          this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
        }
      })
    },

    tabSwitch(item) {
      this.order_status = item
      this.listFn()
    },
    childTab(item) {
      console.log(item);
      this.$store.commit('childTab', item.activeTitle)
      console.log(item);
      this.fullscreenLoading = true

      switch (item.activeTitle) {
        case '在线充值':
          this.$router.push({
            path: '/user/refill',
          })
          break;
        case '申请发票':
          this.$router.push({
            path: '/user/ApplyForInvoice/ApplyForInvoiceData',
          })
          break;
        case '选择媒介':
          this.$router.push({
            path: '/user/userMedia/News',
          })
          break;
        case '一言一文':
          this.$router.push({
            path: '/user/Myword',
          })
          break;
        case '媒介订单':
          this.$router.push({
            path: '/user/MediaOrder',
            query: {
              status: '已处理'
            }
          })
          break;
        case '退稿记录':
          this.$router.push({
            path: '/user/MediaOrder',
            query: {
              status: '退稿'
            }
          })
          break;
        case '我的工单':
          this.$router.push({
            path: '/user/MyOrder'
          })
        default:
          break;
      }
      this.fullscreenLoading = false

    },
    listFn() {
      let data = {}
      console.log(this.order_status);
      data.page = this.page
      data.limit = 5
      if (this.order_status.id !== '') {
        data.order_status = this.order_status.id
      }
      this.curlGet('/api/users/order/list', data).then(res => {
        if (res.data.code) {
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
          this.tableData = res.data.data.list
          this.tableData.forEach((item, index) => {
            this.tableData[index].order_time = timeCut(this.tableData[index].order_time * 1000)

          })
        }
      })
    }
  },
}
</script>

<style lang=scss scoped>
@import '../../scss/UserInfo.scss'
</style>