<template>
	<div class="flex" style="justify-content: space-between; align-items: center">
		<p class="title">{{ title }}</p>
		<button @click.stop="back" v-show="backShow" class="but">返回</button>
		<button @click.stop="backFill" v-show="backShow2" class="but">返回编辑</button>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: '',
	props: {
		title: String,
		backShow: Boolean,
		backShow2: Boolean
	},
	computed: {
		...mapState(['shopObj', 'fillActive']),
	},
	created() { },
	mounted() { },
	data() {
		return {}
	},
	methods: {
		back() {
			this.$store.state.fillActive = true
			this.$router.back()
		},
		backFill() {
			if (this.$store.state.shopObj[0].category_id == 0) {

				this.$router.push(`/user/SetMenu/fill?id=8`)
			} else {
				this.$router.push(`/user/SetMenu/fill?id=${this.$store.state.shopObj[0].category_id}`)
			}
		}
	},
}
</script>

<style lang=scss scoped>
.title {
	position: relative;
	z-index: 999;
	color: #333333;
	font-size: 1.6rem;
	margin-bottom: 1.8rem;
	font-weight: 400;
}

.but {
	border: 0;
	width: 5.67rem;
	height: 2.5rem;
	opacity: 1;
	border-radius: 0.42rem;
	background: #108cdd;
	color: #fff;
	box-shadow: 2px 2px 4px rgba(122, 122, 122, 0.25);
}
</style>