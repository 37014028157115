<template>
  <div style="position: relative;padding-left: 30px; box-sizing: border-box;">
    <span class="trumpet_icon"></span>
    <el-carousel height="30px" direction="vertical" :autoplay="true">
    <el-carousel-item v-for="item in ScrollObj" :key="item.id">
      <p class="medium">{{ item.msg }}</p>
    </el-carousel-item>
  </el-carousel>
  </div>
</template>

<script>
  export default {
    name:'',
    props:{
        ScrollObj:Array
    },
    mounted(){},
    data(){
      return{}
    },
    methods:{},
 }
</script>

<style lang=scss scoped>
.trumpet_icon{
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 20px;
    height: 20px;
    background: url('~@/assets/user/index/trumpet.png')no-repeat;
    background-size: 100%;
}
.medium{
  color: #c38f4d;
    font-size: 14px;
    
}
</style>